var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "side" }, [
    _c("img", {
      staticClass: "banner",
      attrs: { src: require("@/assets/img/commerce-debut-guide/banner.webp") },
    }),
    _c("div", { staticClass: "menu" }, [
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: { active: _setup.selectedMenu === "menu1" },
          on: {
            click: function ($event) {
              return _setup.selectMenu("menu1")
            },
          },
        },
        [
          _c("img", {
            staticClass: "menu-icon",
            attrs: {
              src: require("@/assets/img/commerce-debut-guide/menu-icon-1.webp"),
            },
          }),
          _vm._m(0),
          _c("span", { staticClass: "menu-arrow" }, [_vm._v("＞")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: { active: _setup.selectedMenu === "menu2" },
          on: {
            click: function ($event) {
              return _setup.selectMenu("menu2")
            },
          },
        },
        [
          _c("img", {
            staticClass: "menu-icon",
            attrs: {
              src: require("@/assets/img/commerce-debut-guide/menu-icon-2.webp"),
            },
          }),
          _vm._m(1),
          _c("span", { staticClass: "menu-arrow" }, [_vm._v("＞")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: { active: _setup.selectedMenu === "menu3" },
          on: {
            click: function ($event) {
              return _setup.selectMenu("menu3")
            },
          },
        },
        [
          _c("img", {
            staticClass: "menu-icon",
            attrs: {
              src: require("@/assets/img/commerce-debut-guide/menu-icon-3.webp"),
            },
          }),
          _vm._m(2),
          _c("span", { staticClass: "menu-arrow" }, [_vm._v("＞")]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: { active: _setup.selectedMenu === "menu4" },
          on: {
            click: function ($event) {
              return _setup.selectMenu("menu4")
            },
          },
        },
        [
          _c("img", {
            staticClass: "menu-icon",
            attrs: {
              src: require("@/assets/img/commerce-debut-guide/menu-icon-4.webp"),
            },
          }),
          _vm._m(3),
          _c("span", { staticClass: "menu-arrow" }, [_vm._v("＞")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "menu-content" }, [
      _c("span", { staticClass: "menu-text" }, [
        _vm._v("Nolaで商業化を目指すコツを読む"),
      ]),
      _c("span", { staticClass: "menu-description" }, [
        _vm._v("はじめに、こちらをご一読ください。"),
        _c("br"),
        _vm._v("商業化を目指す全体の流れをご確認いただけます。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "menu-content" }, [
      _c("span", { staticClass: "menu-text" }, [
        _vm._v("Nolaエージェントとは？"),
      ]),
      _c("span", { staticClass: "menu-description" }, [
        _vm._v("作家の皆さまから「エージェントに提出」いただいた"),
        _c("br"),
        _vm._v("作品の商業化を、Nolaが営業面でサポートする機能です。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "menu-content" }, [
      _c("span", { staticClass: "menu-text" }, [_vm._v("作品投稿とは？")]),
      _c("span", { staticClass: "menu-description" }, [
        _vm._v("各社の編集部と繋がり、デビューに近づく小説投稿サイト"),
        _c("br"),
        _vm._v("「Nolaノベル」へ作品をご投稿いただけます。"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "menu-content" }, [
      _c("span", { staticClass: "menu-text" }, [
        _vm._v("みんなのNolaの使い方を知る"),
      ]),
      _c("span", { staticClass: "menu-description" }, [
        _vm._v("Nolaから作品を商業化された作家の皆さまや、編集部の"),
        _c("br"),
        _vm._v("皆さまに、Nolaの使い方をお伺いしました。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }